.email-response {
  text-align: center;
  padding: 30px 0;
  font-family: "Montserrat", sans-serif;
}

.logo-image {
  width: 60px;
  margin-bottom: 30px;
}

.confirmed-image {
  width: 300px;
  margin-bottom: 40px;
}

.heading {
  padding: 0 360px;
  text-align: left;
}

.heading-1 {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 4.38px;
  color: #fbb12f;
  margin-bottom: 20px;
}

.heading-2 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1.67px;
  color: #231f20;
  margin-bottom: 40px;
}

.heading-3 {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #231f20;
}

.login {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.login a {
  color: #fbb12f;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .heading {
    padding: 0 30px;
  }
}
